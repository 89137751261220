.curtain {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 20;
    background-color: rgba(0, 0, 0, 0.6);
}

.modal {
    @extend .card;
    @extend .p-s;

    position: fixed;
    display: flex;
    flex-direction: column;
    align-items: center;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow-y: scroll;
    height: fit-content;
    margin: auto;
    width: 100%;
    max-width: $modal-width;
    max-height: calc(100vh - 200px);
    overflow-y: auto;
    z-index: 20;
    animation: fade-in 0.2s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;

    &--large {
        max-width: 80vw;
    }

    &--assign {
        overflow: scroll;

    }

    .form-row {
        width: 100%;
    }

    .close_modal {
        display: flex;
        justify-content: flex-end;
        width: 100%;

        &__button {
            @extend .cta-transition;
            background-color: black;
            padding: 0.2rem;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;

            &:hover {
                background: $blue;
            }

            & i {
                color: $white;
            }
        }
    }

    .new_user_account_modal-disclaimer {
        font-size: 13px;
        margin-bottom: 20px;

        .bold {
            font-weight: 600;
            margin-top: 20px;
        }

        p {
            margin-bottom: 10px;
            font-weight: 300;
        }
    }

    .new_employee_account_modal-disclaimer {
        p {
            font-weight: 400;
            font-size: 16px;
            line-height: 20px;
            letter-spacing: 0.5px;
        }

        .bold {
            font-weight: 600;
        }

        .disclaimer_search_section {
            margin-top: 25px;

            .custom-select-container {
                margin-top: 15px;
            }

        }

        margin-bottom: 25px;
    }

    .link_organisation-dropdown {
        .react-dropdown-select-dropdown {
            overflow-x: hidden;
            height: 135px;
        }
    }

    .link-organisation_banner {
        margin-top: 40px;
        width: 100%;
    }

    @-webkit-keyframes fade-in {
        0% {
            opacity: 0
        }

        100% {
            opacity: 1
        }
    }

    @keyframes fade-in {
        0% {
            opacity: 0
        }

        100% {
            opacity: 1
        }
    }
}

.edit_employee-modal {
    max-width: 750px;
}