.table_account_type_icon {
    display: flex;
    justify-content: center;

    &__icon {
        position: relative;
        width: fit-content;
        margin-left: auto;

        .generic_tool_tip {
            display: none;
        }

        &:hover {
            .generic_tool_tip {
                display: block !important;
            }
        }
    }
}