// react-awesome-lightbox
.lb-container {
    position: absolute !important;
    z-index: 0;

    .lb-icon-close {
        display: none;
    }
}

// react-step-progress
.progress-bar-container {
    padding: 0;
    width: 100%;
    z-index: 0;
    background: $cream-white;
    padding: 60px 0 40px 0;

    .progress-line {
        z-index: 0;

        ._35Ago {
            &::before {
                background: $primary;
            }

            ._2kL0S {
                background-color: $primary;
            }
        }

        ._2ZUAI {
            &::before {
                background: $primary;
            }

            ._2JvrO {
                background-color: $primary;
            }
        }
    }

    .progress-button-container {
        display: none;
    }

    .step-label {
        @extend .m-t-xs;

        font-size: 0.8rem;
    }
}

// react-toastify
.Toastify__toast {
    @extend .card;
    @extend .p-s;

    .Toastify__toast-body {
        font-family: $font-family;
        color: #212120;
        ;
        font-size: 0.8rem;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        letter-spacing: 0.5px;
    }

    .Toastify__progress-bar--animated {
        right: 0;
        left: initial;
        transform-origin: right;
    }

    .Toastify__progress-bar {
        background: $primary;
    }

    &--error {
        .Toastify__progress-bar {
            background: red;
        }
    }
}

// react-dropdown-select
.react-dropdown-select {
    border: none !important;
    background: $cream-white !important;
    min-height: 50px !important;
    border-radius: $border-radius-m !important;
    font-size: 0.85rem !important;
    box-shadow: none !important;
    padding-left: $space-xs !important;
    padding-right: $space-xs !important;

    .react-dropdown-select-content {
        span {
            font-size: 0.65rem;
        }
    }

    .react-dropdown-select-dropdown {
        border-radius: $border-radius-m;
        border: none;

        .react-dropdown-select-item {
            border: none;
            line-height: 30px;
            font-size: 0.7rem;

            &:hover:not(.react-dropdown-select-item-selected) {
                background-color: $cream-white;
                transition: 0.2s;
            }

            &.react-dropdown-select-item-selected {
                background-color: $primary;
            }
        }
    }

    .react-dropdown-select-option {
        background-color: $primary;
        padding-left: 10px;
        border-radius: $border-radius-l;

        .react-dropdown-select-option-label {
            font-size: 0.6rem;
        }

        .react-dropdown-select-option-remove {
            margin: 0 -3px 0 0px;
        }
    }

    .react-dropdown-select-dropdown-add-new {
        font-size: 0.7rem;
        background-color: $primary;
        color: $white;
        text-align: center;
        line-height: 30px;

        &:hover {
            background-color: $primary;
            opacity: 0.8;
        }
    }

    .react-dropdown-select-no-data {
        font-size: 0.7rem;
        color: $primary;
    }
}

// react-select
.custom-select-container {
    position: relative;
    width: 100%;

    /*    .react-dropdown-select{
        padding:0px !important;
    }*/
    .react-dropdown-select-dropdown {
        animation: swing-in-top-fwd 0.3s cubic-bezier(0.075, 0.820, 0.165, 1.000) both;
        position: absolute !important;
        z-index: 1;
    }

    &--hide_dropdown {
        .react-dropdown-select-dropdown {
            display: none
        }
    }

    &--higher_opacity--disabled {
        .react-dropdown-select {
            opacity: 0.8;
        }
    }

    .react-dropdown-select-clear {
        color: white;
        font-size: 20px;
        font-weight: bold;
        background: rgba(179, 179, 179, 1);
        font-weight: 300;
        border-radius: 50%;
        width: 15px;
        height: 15px;
        min-height: 15px;
        min-width: 15px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        margin: 0;
    }

    .react-dropdown-select-clear:hover {
        background: rgb(145, 144, 144);
        color: white;
    }

    @-webkit-keyframes swing-in-top-fwd {
        0% {
            -webkit-transform: rotateX(-100deg);
            transform: rotateX(-100deg);
            -webkit-transform-origin: top;
            transform-origin: top;
            opacity: 0
        }

        100% {
            -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg);
            -webkit-transform-origin: top;
            transform-origin: top;
            opacity: 1
        }
    }

    @keyframes swing-in-top-fwd {
        0% {
            -webkit-transform: rotateX(-100deg);
            transform: rotateX(-100deg);
            -webkit-transform-origin: top;
            transform-origin: top;
            opacity: 0
        }

        100% {
            -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg);
            -webkit-transform-origin: top;
            transform-origin: top;
            opacity: 1
        }
    }
}


// Sage pay form
.payment-form {
    width: 100%;

    #sp-container {
        position: relative;
        height: 165px;
    }
}