.separator {
  position: static;
  height: 0px;
  left: 0px;
  top: 103px;
  border-radius: 0;
  border: 2px solid #EFEFEF;
  flex: none;
  order: 1;
  flex-grow: 0;
  margin: 24px 0px;
}