.document_container {
    display: flex;
    width: 100%;
    height: fit-content;
    justify-content: start;

    .dropzone {
        background-color: #f4f3ef;
        border: 3px dashed #cccccc;
        border-radius: 10px;
        cursor: pointer;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        justify-content: center;
        padding: 4%;
        width: 278px;
        max-height: 220.75px;

        p {
            font-family: "Montserrat";
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 20px;
            text-align: center;
            letter-spacing: 0.5px;
        }

        img {
            margin-bottom: 20px;
        }
    }

    .images {
        display: flex;
        flex-wrap: wrap;
        gap: 10px;
    }

    .image-container {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 278px;
        height: 220.75px;
        margin-right: 20px;
        border: 1px solid #b3b3b3;
        border-radius: 10px;
        word-break: break-all;
        padding: 10px;

        a {
            margin-top: 10px;
            color: #000;
            display: flex;
            flex-direction: column;
            width: 90%;

            :hover {
                transition: 0.2s;
                background: #e5e5e5;
                border-radius: 0.25rem;
                cursor: pointer;
            }

            p {
                font-family: "Montserrat";
                font-weight: 400;
                font-size: 16px;
                line-height: 20px;
                text-align: center;
                letter-spacing: 0.5px;
                margin-top: 16px;
            }

            img {
                max-width: 80px;
                object-fit: contain;
                margin: 0 auto;
            }
        }
    }

    .mediaAccessLevel {
        margin: 10px;
        margin-top: auto;
        width: 80%;
        border: 1px solid lightgray;
        padding: 1px;
        display: flex;
        flex-direction: column;
        color: rgba(0, 0, 0, 0.6);

        select {
            height: 30px;
        }

        :hover {
            color: black;
        }
    }

    .delete-button {
        position: absolute;
        top: 10px;
        right: 10px;
        border-radius: 50%;
        width: 25px;
        height: 25px;
        display: flex;
        align-items: center;
        justify-content: center;

        :hover {
            transition: 0.2s;
            background: rgb(241, 219, 219);
            border-radius: 1rem;
            cursor: pointer;
        }
    }

    .add-image {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 20px;
        color: #aaa;
        margin-top: 10px;
    }
}