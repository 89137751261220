.banner {
    width: 100%;
    border-radius: 4px;
    padding: 16px;
    display: flex;
    align-items: center;

    &--warning {
        background-color: #FFF5E6;
    }

    &--error {
        background-color: transparent;
        color: red;
        font-weight: 700;
        padding: 0;

    }

    &--info {
        background-color: #F0F1FF;

        &--delay {
            font-style: italic;
            background: linear-gradient(90deg, #F0F1FF, $white);
        }
    }

    &--fixed {
        position: fixed;
        z-index: 2;
        top: 60px;
        left: 259px;

        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 17px;
        letter-spacing: 0.5px;

        @include tablet {
            left: 60px;
        }

        @include mobile {
            left: 60px;
        }
    }

    &--gray {
        background-color: #E5E5E5;
        z-index: 10;
    }

    &--blue {
        background-color: rgba(240, 241, 255, 1)
    }

    &--red {
        background-color: #FEF3CD;
    }

    &--expired {
        z-index: 10;
        background-color: #FCEAE8;
        color: #E74C3C;
    }

    &--iconFlexStart {
        .banner__icon {
            align-self: flex-start;
        }
    }

    &__icon {
        margin-right: 1rem;
        display: flex;
        align-items: center;
    }

    &__body {
        font-family: $font-family;
        font-size: 16px;
        line-height: 20px;
        letter-spacing: 0.5px;
        width: 100%
    }
}

.new_organisation-banner {
    margin-bottom: 24px;
}