.radio__selector {
    display: flex;
    width: 100%;
    align-items: center;
    flex-direction: row;

    &--reversed {
        flex-direction: row-reverse;
        justify-content: flex-end;

        .radio__selector__select {
            margin-left: 0rem;
            margin-right: 1rem;
        }
    }

    &__select {
        border: 2px solid black;
        height: 35px;
        width: 35px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        margin-left: 1rem;

        &__fieller {
            background: black;
            width: 80%;
            height: 80%;
            border-radius: 50%;
        }
    }
}