$sizes: (
	"xxs": $space-xxs,
	"xs": $space-xs,
	"s": $space-s,
	"m": $space-m,
	"l": $space-l,
	"xl": $space-xl,
	"xxl": $space-xxl,
	"none": 0px,
);

// Padding
@each $key, $value in $sizes {

	// All
	.p-#{$key} {
		padding: $value;
	}

	// Vertical axis
	.p-v-#{$key} {
		padding-top: $value;
		padding-bottom: $value;
	}

	// Horizontal axis
	.p-h-#{$key} {
		padding-left: $value;
		padding-right: $value;
	}

	// Top
	.p-t-#{$key} {
		padding-top: $value;
	}

	// Bottom
	.p-b-#{$key} {
		padding-bottom: $value;
	}

	// Left
	.p-l-#{$key} {
		padding-left: $value;
	}

	// Right
	.p-r-#{$key} {
		padding-right: $value;
	}
}

// Margin
@each $key, $value in $sizes {

	// All
	.m-#{$key} {
		margin: $value;
	}

	// Vertical axis
	.m-v-#{$key} {
		margin-top: $value;
		margin-bottom: $value;
	}

	// Horizontal axis
	.m-h-#{$key} {
		margin-left: $value;
		margin-right: $value;
	}

	// Top
	.m-t-#{$key} {
		margin-top: $value;
	}

	// Bottom
	.m-b-#{$key} {
		margin-bottom: $value;
	}

	// Left
	.m-l-#{$key} {
		margin-left: $value;
	}

	// Right
	.m-r-#{$key} {
		margin-right: $value;
	}
}

$grid__cols: 12;

@for $i from 1 through $grid__cols {
	.col-#{$i} {
		width: (100 / ($grid__cols / $i) - 0.5) * 1%;

		&:not(.block) {
			display: flex;
			align-items: center;
		}
	}
}

// .table-col class goes from 1 to 24, which is a 12-grid multiplied by 2. This enables 0.5 steps in tables.
$table__grid__cols: 24;

@for $i from 1 through $table__grid__cols {
	.table-col-#{$i} {
		width: (100 / ($table__grid__cols / $i) - 0.1) * 1%;
		max-width: (100 / ($table__grid__cols / $i) - 0.1) * 1%;
	}
}

.uppercase {
	text-transform: uppercase;
}

.d-flex {
	display: flex;
}

.flex-col {
	display: flex;
	flex-direction: column;
}

.flex-row {
	display: flex;
	flex-direction: row;
}

.align-i-c {
	align-items: center;
}

.justify-c-c {
	justify-content: center;
}

.justify-c-e {
	justify-content: flex-end;
}

.justify-c-s {
	justify-content: flex-start;
}

.justify-c-sb {
	justify-content: space-between;
}

.align-i-start {
	align-items: start !important;
}