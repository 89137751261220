 .radio-container {

   .radio {
     position: relative;
     display: flex;
     margin-bottom: 20px;
     cursor: pointer;
     align-items: center;

     .option-text {
       font-weight: 600;
       font-size: 0.86rem
     }

     .inner-label {
       position: relative;
       display: inline-block;
       background-color: $white;
       width: 40px; // Outer size
       height: 40px; // Outer size
       border: 2px solid $secondary;
       border-radius: 50%;
       -webkit-tap-highlight-color: transparent;
       margin-right: 20px;

       &:after {
         content: '';
         position: absolute;
         top: 4px; // Inner offset
         left: 4px; // Inner offset
         width: 28px; // Inner size
         height: 28px; // Inner size
         border-radius: 100%;
         background: $secondary;
         transform: scale(0);
         transition: all .2s ease;
         opacity: .08;
         pointer-events: none;
       }
     }

     &:hover {
       .inner-label:after {
         transform: scale(2);
       }
     }
   }


   input[type="radio"]:focus+.inner-label:after {
     transform: scale(2);
   }

   input[type="radio"]:checked+.inner-label {
     border-color: $secondary;

     &:after {
       transform: scale(1);
       transition: all .2s cubic-bezier(.35, .9, .4, .9);
       opacity: 1;
     }
   }
 }