h1,
.h1 {
    font-size: 2em;
}

h2,
.h2 {
    font-size: 1.8em;
}

h3,
.h3 {
    font-size: 1.6em;
}

h4,
.h4 {
    font-size: 1.4em;
}

h5,
.h5 {
    font-size: 1.2em;
}

h6,
.h6 {
    font-size: 1em;
}