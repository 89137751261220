.toggler {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 33px;

    input {
        opacity: 0;
        width: 0;
        height: 0;
    }

    .slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: $light-gray;
        -webkit-transition: .4s;
        transition: .4s;

        &:before {
            position: absolute;
            content: "";
            height: 23px;
            width: 23px;
            left: 6px;
            bottom: 5px;
            background-color: #FFF;
            -webkit-transition: .4s;
            transition: .4s;
        }

        &.round {
            border-radius: 34px;

            &:before {
                border-radius: 50%;
            }
        }
    }

    input:checked+.slider {
        background-color: $primary
            /* $green-dark */
        ;
    }

    input:checked+.slider:before {
        -webkit-transform: translateX(26px);
        -ms-transform: translateX(26px);
        transform: translateX(26px);
    }

}