.table_rewrite_flag {
    display: flex;
    justify-content: center;

    &__icon {
        position: relative;
        width: fit-content;

        .generic_tool_tip {
            .generic_tool_tip__pointer {
                left: 10px;
            }

            display: none;
            right: initial;
        }

        &:hover {
            .generic_tool_tip {
                display: block !important;
            }
        }
    }
}