.banner_button {
	background: transparent;
	outline: none;
	border: 2px solid #F9D5D2;
	padding: 5px 8px;
	border-radius: 4px;
	display: flex;
	align-items: center;
	cursor: pointer;
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 20px;
	transition: all 0.2s ease;
	letter-spacing: 0.5px;
	flex-wrap: nowrap;
	text-align: center;

	&:hover {
		background: #F9D5D2;
	}

	color:#E74C3C;

	&--blue {
		color: rgba(47, 62, 251, 1);
		border: 2px solid #CDD1FE;

		&:hover {
			background: #DCDEFE;
		}
	}
}