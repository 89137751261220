 .square-checkbox-container {
   &--large_label {
     .square-checkbox-text {

       font-size: 16px;
       font-weight: 400;
     }
   }

   &--disabled {
     .square-checkbox-text {

       color: grey;
       font-weight: 400;
     }
   }

   .square-checkbox {
     position: relative;
     display: flex;
     cursor: pointer;
     align-items: center;

     .square-checkbox-text {
       font-size: 14px;
       line-height: 17px;
       letter-spacing: 0.5px;
     }

     .square-checkbox-text-right {
       font-weight: 600;
       font-size: 0.86rem;
       padding: 0.3rem;
     }

     .inner-label {
       position: relative;
       display: flex;
       justify-content: center;
       align-items: center;
       background-color: $white;
       min-width: 30px; // Outer size
       height: 30px; // Outer size
       border: 2px solid $secondary;
       border-radius: 10%;
       -webkit-tap-highlight-color: transparent;
       margin-right: 20px;

       &--checked {
         background: black;
       }

       &--disabled {
         border: 2px solid $border-color;
       }
     }
   }

   input[type="checkbox"]:focus+.inner-label:after {
     transform: scale(2);
   }

   input[type="checkbox"]:checked+.inner-label {
     border-color: $secondary;
   }
 }

 .remove_shipping--checkbox {
   margin-bottom: 20px;

   .square-checkbox {
     .square-checkbox-text {
       font-weight: 700;
       font-size: 20px;
       line-height: 24px;
       letter-spacing: 0.5px;
     }
   }
 }

 .bold_text {
   label {

     span,
     a {
       font-weight: 700 !important;
       font-size: 20px !important;
       line-height: 24px !important;
       letter-spacing: 0.5px !important;
     }
   }
 }