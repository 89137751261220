.algorithm-option-container {

  margin: 10px 0;
  width: 100%;
  display: flex;
  flex-direction: row;
  border: 1px solid #808080;
  border-radius: 4px;

  .algorithm-option-text {
    padding: 16px;
    width: 90%;

    p {
      font-size: 1rem;
      margin-bottom: 16px;
    }

    span {}
  }

  .algorithm-option-option {
    padding-left: 10px;
    display: flex;
    justify-content: center;
    align-items: center;

    .radio-container .radio {
      margin-bottom: 0;
    }
  }

  &__clickable:hover {
    background-color: $extra-light-gray;
    cursor: pointer;
  }
}