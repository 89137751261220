header {
    @extend .p-h-s;

    display: flex;
    position: fixed;
    align-items: center;
    justify-content: space-between;
    top: 0;
    left: $aside-width;
    right: 0;
    height: $header-height;
    border-bottom: 1px solid $border-color;
    background-color: $cream-white;
    z-index: 11;

    .breadcrumbs {
        text-transform: uppercase;

        a,
        span {
            font-size: 0.6rem !important;
        }

        .separator {
            @extend .m-h-xs;
        }
    }

    .account-actions-container {
        position: relative;
        display: inline-flex;
        align-items: center;
        height: $header-height;
        cursor: pointer;

        .active-administrator-name {
            font-size: 0.6rem;
            text-transform: uppercase;
            font-weight: 600;

            &::after {
                display: inline-block;
                margin-left: 0.255em;
                vertical-align: 0.255em;
                content: "";
                border-top: 0.3em solid;
                border-right: 0.3em solid transparent;
                border-bottom: 0;
                border-left: 0.3em solid transparent;
            }
        }

        .account-navigation {
            @extend .card;

            position: absolute;
            display: none;
            top: 60px;
            right: 0;
            width: 140px;
            overflow: visible;

            &::after {
                border-bottom: 11px solid #FFFFFF;
                border-left: 11px solid rgba(0, 0, 0, 0);
                border-right: 11px solid rgba(0, 0, 0, 0);
                content: "";
                display: inline-block;
                position: absolute;
                right: 12px;
                top: -11px;
            }

            a,
            span {
                @extend .p-h-xs;

                display: block;
                width: 140px;
                height: 44px;
                line-height: 44px;
                font-size: 0.7rem;
                text-decoration: none;

                &:first-child {
                    border-radius: 10px 10px 0 0;
                }

                &:last-child {
                    border-radius: 0 0 10px 10px;
                }

                &:first-child:last-child {
                    border-radius: 10px;
                }

                &:hover {
                    color: $white;
                    background-color: $primary;
                }
            }

            span {
                color: $primary;
            }
        }

        &:hover {
            .account-navigation {
                animation: swing-in-top-fwd .5s cubic-bezier(.175, .885, .32, 1.275) both;
                display: block;
            }
        }
    }

    @keyframes swing-in-top-fwd {
        0% {
            transform: rotateX(-100deg);
            transform-origin: top;
            opacity: 0
        }

        100% {
            transform: rotateX(0deg);
            transform-origin: top;
            opacity: 1
        }
    }
}

@include tablet {
    header {
        left: $aside-width-mobile;
    }
}

@include mobile {
    header {
        left: $aside-width-mobile;
    }
}