.generic_tool_tip {
    background: $gray;
    padding: 0.3rem;
    border-radius: 0.3rem;
    position: absolute;
    width: max-content;
    max-width: 300px;
    right: 10px;
    top: 30px;
    z-index: 1;
    color: $cream-white;
    animation: fade-in 0.2s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;

    &__text {
        z-index: 10;
        font-style: normal;
        font-weight: normal;

        // max-width: 300px;
        // add if there is need for responsive behavior
    }

    &__pointer {
        width: 11px;
        height: 11px;
        background-color: $gray;
        position: absolute;
        transform: rotate(45deg);
        top: -6px;
        right: 10px;
    }

    &--right {
        right: initial !important;

        .generic_tool_tip__pointer {
            top: -5px;
            left: 5px;
        }
    }

    &--left {
        right: 0;
        top: 30px;

        .generic_tool_tip__pointer {
            top: -5px;
            right: 5px;
        }
    }

    &--up {
        top: -40px !important;
        right: 3px;

        .generic_tool_tip__pointer {
            top: initial;
            bottom: -3px !important;
            right: 3px !important;
        }
    }

    @-webkit-keyframes fade-in {
        0% {
            opacity: 0
        }

        100% {
            opacity: 1
        }
    }

    @keyframes fade-in {
        0% {
            opacity: 0
        }

        100% {
            opacity: 1
        }
    }
}