.dropdown-container {
    position: relative;
    display: inline-block;
    width: 100%;

    select {
        @extend .p-h-xs;

        height: 50px;
        width: 100%;
        border: none;
        outline: none;
        font-family: $font-family;
        background: $cream-white;
        border-radius: $border-radius-m;
        cursor: pointer;

        &::placeholder {
            color: $light-gray;
        }
    }
}