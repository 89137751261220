.tableDrawerContainer {
    padding: 1rem;

    .card {
        box-shadow: none;
        border: none;
    }

    .table-heading {
        display: none;
    }
}