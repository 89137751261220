.input-container {
    position: relative;
    display: inline-block;
    width: 100%;

    &--no_opacity_label {
        label {
            opacity: 1;
        }
    }

    input:disabled {
        opacity: 0.8;
    }

    &--currency {
        input {
            background: url('../../../assets/icons/PoundSignBlack.svg') no-repeat scroll 7px 19px, $cream-white !important;
            padding-left: 30px !important;
        }

        input:disabled {
            background: url('../../../assets/icons/PoundSignGray.svg') no-repeat scroll 7px 19px, $cream-white !important;
        }
    }

    input[type=text],
    input[type=number],
    input[type=date],
    input[type=password] {
        @extend .p-h-xs;

        height: 50px;
        width: 100%;
        border: none;
        outline: none;
        font-family: $font-family;
        background: $cream-white;
        border-radius: $border-radius-m;
        padding-right: 30px; // Adjust padding to not overlap with clear icon

        &::placeholder {
            color: $light-gray;
        }
    }

    &--stock {
        margin-top: 10px;
        width: 180px;

        label {
            top: -40px;
        }
    }

    .clear-icon {
        position: absolute;
        top: 50%;
        right: 10px;
        transform: translateY(-50%);
        width: 20px;
        height: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        border-radius: 50%;
        transition: 0.2s;

        &:hover {
            padding: 1px;
            background-color: $gray;
        }
    }
}

.stock-quantity-input-set {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;

    .input-container {
        margin-top: 20px;

        label {
            margin-top: 20px;
            top: -60px;
        }
    }

    button {
        margin: 10px 16px;
    }
}

.alocate_header--button {
    display: flex;
    justify-content: center;
    width: 50%;
    padding-bottom: 10px;
    border-bottom: 1px solid rgba($color: #CCCCCC, $alpha: 1.0);
    color: #808080;
    font-weight: 600;
    cursor: pointer;

    &.active {
        color: #000000;
        border-bottom: 1px solid rgba($color: #000000, $alpha: 1.0);
    }
}