.heading-filter-container {
	@extend .p-h-s;
	@extend .p-v-s;

	display: flex;
	justify-content: space-between;
	align-items: flex-start;
	min-height: 110px;

	.table-heading {
		font-size: 0.82rem;

		display: inline-block;
		text-overflow: ellipsis;
		overflow: hidden;

		background-color: #2c495b;
		color: $white;
		padding: 0 $space-s;
		height: 42px;
		line-height: 42px;
		border-radius: 21px;
	}

	.split_orded--badge {
		background-color: #EFEFEF;
		font-size: 0.82rem;

		display: inline-block;
		text-overflow: ellipsis;
		overflow: hidden;

		color: $split-order-text-color;
		padding: 0 $space-s;
		height: 42px;
		line-height: 42px;
		border-radius: 21px;
		margin-left: 10px;
	}

	.filter-container {
		display: inline-flex;
		justify-content: space-between;
		align-items: center;
	}

	.tracking_code {
		display: flex;

		.dot_p {
			margin: 0 5px;
		}
	}
}

table {
	border-collapse: collapse;

	tr:not(.table-header):not(.table-body) {
		border-bottom: 1px solid $border-color;

		th,
		td {
			display: inline-block;

			padding: 0 10px;
			text-overflow: ellipsis;

			word-break: break-word;

			&.right {
				text-align: right;
				text-align: -webkit-right;
			}

			&.center {
				text-align: center;
				text-align: -webkit-center;
			}
		}

		th {
			// overflow: hidden;
			min-height: $table-row-height;
			hyphens: auto;
		}

		td {
			height: $table-row-height;
			display: table-cell;
			align-items: center;
		}
	}

	tr:not(.table-header):not(.table-body):last-child {
		border-bottom: none !important;
	}

	.table-header {
		font-size: 0.7rem;
		text-transform: uppercase;

		&>div {
			padding: 0 $space-s 0 $space-xs;
		}

		th {
			text-align: left;
			color: $primary;
			user-select: none;
		}
	}

	.table-body {
		animation: swing-in-top-fwd 0.5s cubic-bezier(0.455, 0.030, 0.515, 0.955);

		a:not(.badge):not(.btn),
		td,
		span:not(.colored) {
			font-size: 0.7rem;
		}

		td {
			* {
				vertical-align: middle;
			}

			.table-cell-tooltip {
				@extend .card;
				@extend .p-h-xs;
				@extend .p-b-s;

				opacity: 0;
				position: fixed;
				right: 0;
				bottom: 30px;
				width: 200px;
				pointer-events: none;
				-webkit-transition: opacity 0.2s ease-in-out, right 0.2s ease-in-out;
				-moz-transition: opacity 0.2s ease-in-out, right 0.2s ease-in-out;
				-ms-transition: opacity 0.2s ease-in-out, right 0.2s ease-in-out;
				-o-transition: opacity 0.2s ease-in-out, right 0.2s ease-in-out;
				transition: opacity 0.2s ease-in-out, right 0.2s ease-in-out;
				z-index: 99;

				div {
					span {
						@extend .p-h-xs;
						@extend .m-b-xs;

						line-height: 24px;
						height: 24px;
						color: $white;
						border-radius: 12px;
						font-size: 0.55rem;
						font-weight: 600;
						float: right;
					}

					&:first-child {
						@extend .m-b-xs;

						border-bottom: 1px solid $border-color;
						overflow: hidden;

						span {
							color: transparentize($gray, 0.5);
							height: 50px;
							line-height: 50px;
							margin-bottom: 0;
							letter-spacing: 0.3px;
							font-weight: 800;
							text-transform: uppercase;
						}
					}

					&:last-child {
						span {
							color: black;
							margin-bottom: 0;
							height: fit-content;
							min-height: 50px;
							max-height: 150px;
						}
					}
				}
			}

			&:hover {
				&>.table-cell-tooltip {
					opacity: 1;
					right: 20px;
					pointer-events: initial;
				}
			}
		}

		.table-size {
			// @extend .p-h-xs;

			width: 100%;
			height: calc(100vh - 305px);
			overflow: scroll;

			&.medium {
				height: auto;
				max-height: 390px;

				&.fixed {
					height: 390px;
				}
			}

			&.small {
				height: auto;
				max-height: 300px;

				&.fixed {
					height: 300px;
				}
			}
		}

		tr:last-of-Type {
			.generic_tool_tip {
				top: -35px !important;

				.generic_tool_tip__pointer {
					top: initial;
					bottom: -6px !important;
					right: 10px !important;
				}
			}
		}

		.td--border--left--red {
			// border-left: 4px solid #E74C3C;
			border-left: 4px solid #F38118;

			&--background {
				border-left: 4px solid #E74C3C;
				background: #FCEAE8;
			}
		}

		.td--background--red {
			background: #FCEAE8;
		}
	}
}

.colored {
	@extend .p-h-xs;

	display: inline-block;
	height: 28px;
	line-height: 28px;
	border-radius: 14px;
	color: $white;
	font-size: 0.62rem;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.clickable {
	cursor: pointer;
	border-radius: $border-radius-s;
	user-select: none;

	&:hover,
	&:focus {
		transition: 0.2s;
		background-color: transparentize($cream-white, 0.3);
	}

	&:active {
		background-color: $cream-white;
	}
}

.table-item-inline-action {
	display: flex;
	justify-content: center;
	align-items: center;

	width: 48px;
	height: 17px;
	padding: 13px 10px;

	border: 1px solid #CCCCCC;
	border-radius: 5px;
	background: transparent;

	cursor: pointer;
	margin-right: 10px;
}

.table-item-action {
	display: inline;
	height: 24px;
	cursor: pointer;

	&:first-child {
		margin-right: 10px;
	}

	&>i {
		@extend .cta-transition;

		display: inline-block;
		font-size: 0.6rem !important;
		text-align: center;
		width: 24px;
		height: 24px;
		line-height: 24px;
		background-color: $light-gray;
		color: $white;
		border-radius: 50%;
		vertical-align: top !important;

		&:hover,
		&:focus,
		&:active {
			@extend .form-element--active;
		}
	}
}

.empty-table-disclaimer {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 130px;

	span {
		color: $light-gray;
		font-size: 0.7rem;
	}
}

.stock-table {
	width: 100%;

	tr {
		width: 100%;
		display: flex;
		justify-content: center;

		th {
			width: 33%;
			text-align: left;
			color: #808080;
			font-size: 13px;
			line-height: 16px;
		}

		td {
			width: 33%;
			padding: 16px 0;
			border-bottom: 1px solid #CCCCCC;
		}
	}

	&.centered {

		th,
		td {
			text-align: center;
		}
	}
}

.table-pagination-controls {
	margin-bottom: 10px;
	padding: 10px;
	text-align: right;
	transition: 0.2s;

	.button {
		align-items: center;
		appearance: none;
		background-color: #2c495b;
		background-image: linear-gradient(1deg, #2d495a, #406880 99%);
		background-size: calc(100% + 10px) calc(100% + 10px);
		border-radius: 10px;
		border-width: 0;
		box-shadow: none;
		box-sizing: border-box;
		color: #FFFFFF;
		cursor: pointer;
		display: inline-flex;
		font-size: 0.82rem;
		height: auto;
		justify-content: center;
		line-height: 1;
		padding: 3px 12px;
		margin-right: 6px;
		position: relative;
		text-align: center;
		text-decoration: none;
		transition: background-color .2s, background-position .2s;
		user-select: none;
		-webkit-user-select: none;
		touch-action: manipulation;
		vertical-align: top;
		white-space: nowrap;
	}

	.button-active {
		background-color: #2c495b;
		background-image: linear-gradient(1deg, #2d495a, #406880 99%);
		border: solid #66a6cc;
		text-decoration: underline;
		font-weight: 600;
	}

	.button:focus {
		outline: none;
	}

	.button:hover {
		background-position: -10px -10px;
	}

	.button:focus:not(:active) {
		box-shadow: rgba(40, 170, 255, 0.25) 0 0 0 .125em;
	}

	.button:active {
		transition: 0.1s;
		outline: none;
		box-shadow: 0 3px grey;
		transform: translateY(2px);
	}

	@-webkit-keyframes swing-in-top-fwd {
		0% {
			-webkit-transform: rotateX(-100deg);
			transform: rotateX(-100deg);
			-webkit-transform-origin: top;
			transform-origin: top;
			opacity: 0
		}

		100% {
			-webkit-transform: rotateX(0deg);
			transform: rotateX(0deg);
			-webkit-transform-origin: top;
			transform-origin: top;
			opacity: 1
		}
	}

	@keyframes swing-in-top-fwd {
		0% {
			-webkit-transform: rotateX(-100deg);
			transform: rotateX(-100deg);
			-webkit-transform-origin: top;
			transform-origin: top;
			opacity: 0
		}

		100% {
			-webkit-transform: rotateX(0deg);
			transform: rotateX(0deg);
			-webkit-transform-origin: top;
			transform-origin: top;
			opacity: 1
		}
	}
}