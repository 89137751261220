// General helper classes
.modal-open {
	overflow: hidden;
}

.shadow {
	box-shadow: 0 6px 10px -4px rgba(0, 0, 0, 0.15);
}

.card {
	@extend .shadow;

	width: 100%;
	background-color: $white;
	border-radius: $border-radius-l;
	overflow: hidden;

	.card {
		border: 1px solid #efefef;
	}
}

// Forms
.form-row {
	@extend .m-b-s;
	@extend .p-t-s;

	display: flex;
	justify-content: space-between;
	width: 100%;

	&.no-padding-top {
		padding-top: 0;
	}
}

.form-element--active {
	border-color: $tertiary;
	background-color: $tertiary;
	color: $white;
}

.input--hidden {
	// Hide input but leave it selectable.
	position: absolute;
	opacity: 0;
}

.rounded-info-span {
	@extend .p-h-s;

	display: inline-block;
	background: $primary;
	height: 40px;
	line-height: 40px;
	color: $white;
	border-radius: 20px;
	user-select: none;

	a {
		color: $white;
	}
}

.info-div {
	@extend .p-h-s;
	@extend .p-v-s;

	background: #efefef;
	line-height: 25px;
	font-size: 0.7rem;
	color: black;
	border-radius: 20px;

	a {
		color: $white;
	}
}

// Transitions
.cta-transition {
	-moz-transition: background-color $form-transition-duration ease-in, color $form-transition-duration ease-in,
		border-color $form-transition-duration ease-in !important;
	-webkit-transition: background-color $form-transition-duration ease-in, color $form-transition-duration ease-in,
		border-color $form-transition-duration ease-in !important;
	-o-transition: background-color $form-transition-duration ease-in, color $form-transition-duration ease-in,
		border-color $form-transition-duration ease-in !important;
	transition: background-color $form-transition-duration ease-in, color $form-transition-duration ease-in,
		border-color $form-transition-duration ease-in !important;
}

.animated-border {
	content: "";
	position: absolute;
	bottom: 0.5px;
	left: 50%;
	width: 0;
	height: 1px;
	background: linear-gradient(to right,
			transparent,
			$tertiary,
			$tertiary,
			$tertiary,
			$tertiary,
			$tertiary,
			$tertiary,
			$tertiary,
			$tertiary,
			$tertiary,
			$tertiary,
			$tertiary,
			$tertiary,
			transparent);
	-moz-transition: width $form-transition-duration ease-in, left $form-transition-duration ease-in;
	-webkit-transition: width $form-transition-duration ease-in, left $form-transition-duration ease-in;
	-o-transition: width $form-transition-duration ease-in, left $form-transition-duration ease-in;
	transition: width $form-transition-duration ease-in, left $form-transition-duration ease-in;
}

// Colors
.red {
	background-color: $red !important;
}

.green {
	background-color: $green !important;
}

.light-green-product-status {
	background-color: $light-green-product-status !important;
}

.canceled-status-grey {
	background-color: $canceled-status-grey !important;
	color: #000000 !important;
}

.orange {
	background-color: $orange !important;
}

.light-orange {
	background-color: $light-orange !important;
}

.blue {
	background-color: $blue !important;
}

.yellow {
	background-color: $yellow !important;
}

.gray {
	background-color: $gray !important;
}

.light-gray {
	background-color: $light-gray !important;
}

.color-red {
	color: $red !important;
}

.color-green {
	color: $green !important;
}

.bold {
	font-weight: bold;
}

.normal {
	font-weight: normal;
}

.link {
	text-decoration: underline;
	color: #0000EE !important;
	cursor: pointer;
}

.height-search {
	height: 50px !important;
}

.b-light-orange {
	background-color: $light-orange;
}

.b-extra-light-orange {
	background-color: $extra-light-orange;
}

.b-light-gray {
	background-color: $light-gray;
}

.b-light-green {
	background-color: $light-green;
}

.b-blue {
	background-color: $blue;
}
.m-auto {
	margin: auto;
}

.shadow_text {
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 20px;
	letter-spacing: 0.5px;
	color: #555555;
}

.shadow_text_2 {
	font-style: normal;
	font-weight: 600;
	font-size: 13px;
	line-height: 16px;
	letter-spacing: 0.5px;
	color: #808080;
}

// custom text colors
.t-grey {
	color: rgba($color: #000000, $alpha: 0.4);
}