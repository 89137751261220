.textarea-container {
    position: relative;
    display: inline-block;
    width: 100%;

    textarea {
        @extend .p-xs;

        min-height: 100px;
        max-height: 600px;
        width: 100% !important;
        border: none;
        outline: none;
        font-family: $font-family;
        background: $cream-white;
        border-radius: $border-radius-m;
        vertical-align: top;

        &::placeholder {
            color: $light-gray;
        }
    }
}

.textarea-counter {
    text-align: end;
    padding-top: 0.5rem;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 15px;
    color: #000000;

    &--error {
        color: #E74C3C;
    }
}