.drop_zone {
    max-width: 500px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    &__zone {
        cursor: pointer;

        &--files_selected {
            background: #F2F2F2;
            border: none;
        }

        background: #F6F6F6;
        width: 100%;
        border: 3px dashed #D5D5D5;
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 300px;

        svg {
            height: 49.67px !important;
            width: 46.75px !important;
            margin-bottom: 1rem !important;
        }

        &__text {
            font-family: $font-family;
            font-style: normal;
            font-weight: bold;
            font-size: 16.4px;
            line-height: 20px;
            text-align: center;
            color: #555555;

            &--link {
                color: blue;
                text-decoration: underline;
            }

            &__clear {
                color: red;
                width: fit-content;
                margin: auto;
                margin-top: 1rem;

                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }
}