@mixin mobile {
    @media (max-width: #{$mobile}) {
        @content;
    }
}

@mixin tablet {
    @media (min-width: #{$mobile + 1px}) and (max-width: #{$desktop}) {
        @content;
    }
}