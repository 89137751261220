.drawer {
    display: flex;
    border-bottom: 1px solid #808080;
    ;

    &__button {
        margin-right: 2rem;
        font-style: normal;
        font-weight: bold;
        font-size: 20px;
        line-height: 24px;
        letter-spacing: 0.5px;
        padding-bottom: 1rem;
        transform: translateY(1px);
        color: #808080;
        ;
        cursor: pointer;

        &--active {
            border-bottom: 4px solid $blue ;
            color: initial;
        }
    }
}