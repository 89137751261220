.overflow_select {

  &__button {
    border: 1px solid #CCCCCC;
    padding: 0.4rem;
    border-radius: 5px;
    cursor: pointer;
    font-weight: 500;

    &:hover {
      border: 2px solid rgba(45, 45, 45, 0.84);
    }

    &--none {
      color: #bdbdbd;
    }
  }

  .custom-select-container {
    width: 300px;
  }
}