.input_field_validation_bullet_points {
  &__point {
    color: rgba(85, 85, 85, 1);
    display: flex;
    align-items: center;
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 16px;

    &__circle {
      height: 8px;
      width: 8px;
      min-width: 8px;
      min-height: 8px;
      background: rgba(213, 213, 213, 1);
      margin-right: 16px;
      border-radius: 50%;
    }
  }

  &__point--valid {
    color: rgba(0, 0, 0, 1);

    .input_field_validation_bullet_points__point__circle {
      background: rgba(22, 160, 133, 1);
    }
  }
}