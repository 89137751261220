.custom__card {
    border-radius: 5px;
    padding: 1rem;

    &__text {
        font-family: $font-family;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 17px;
        letter-spacing: 0.5px;
        color: #000000;

        &--bold {
            font-weight: 400;
        }
    }
}