a,
.a {
	font-size: 0.82rem;
	color: #2c31ff;
	cursor: pointer;
	text-decoration: underline;

	&:hover {
		text-decoration: none;
	}

	&.dark {
		color: $secondary;
	}
}