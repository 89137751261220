.process-prescription-tooltip-content {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 6px;

  position: absolute;
  width: 391px;
  height: fit-content;
  left: 0px;
  top: 10px;

  background: #212120;
  border-radius: 5px;

  animation: fade-in 0.2s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;

  &__text {
    position: static;
    left: 3.07%;
    right: 3.07%;
    top: 16%;
    bottom: 16%;
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.5px;
    color: #F4F3EF;
    flex: content;
    order: 0;
    flex-grow: 0;
    margin: 12px;
    text-align: left;

    span {
      text-align: end;
    }
  }

  &__polygon {
    width: 39px;
    height: 20px;
    left: 4px;
    top: 4px;
    border-radius: 2px
  }

  @-webkit-keyframes fade-in {
    0% {
      opacity: 0
    }

    100% {
      opacity: 1
    }
  }

  @keyframes fade-in {
    0% {
      opacity: 0
    }

    100% {
      opacity: 1
    }
  }
}