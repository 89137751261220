.btn {
	@extend .cta-transition;

	display: flex;
	justify-content: center;
	align-items: center;
	height: 70px;
	width: 100%;
	max-width: 280px;
	border: 1px solid $secondary;
	background-color: $secondary;
	color: $white;
	cursor: pointer;
	outline: none !important;
	font-size: 0.82rem;
	letter-spacing: 0.5px;
	font-family: $font-family;
	font-weight: 600;
	text-decoration: none;
	user-select: none;

	svg {
		transition: all $form-transition-duration ease-in;
		min-width: 24px !important;
		min-height: 24px !important;
	}

	&.inverted {
		background-color: transparent;
		color: $secondary;
	}

	&--bg-white {
		background-color: white !important;

		&:hover {
			background-color: $tertiary !important;
		}
	}

	&.primary {
		@extend .form-element--active;

		&:not(:disabled) {

			&:active,
			&:focus,
			&:hover {
				opacity: 0.8;
			}
		}
	}

	&--bordered,
	&--bordered--no_radius {
		@extend .form-element--active;

		&:not(:disabled) {

			&:active,
			&:focus,
			&:hover {
				svg {
					filter: invert(92%) sepia(22%) saturate(1%) hue-rotate(58deg) brightness(107%) contrast(101%);
				}
			}
		}
	}

	&:disabled {
		opacity: 0.4;
	}

	&:not(:disabled) {

		&:active,
		&:focus,
		&:hover {
			@extend .form-element--active;
		}
	}

	i {
		display: inline-block;
		margin: 15px;
	}

	&--bordered {
		background-color: transparent;
		color: black;
		border: 2px solid black;
		border-radius: 5px;
		padding: 0.2rem;
	}

	&--bordered--no_radius {
		background-color: transparent;
		color: black;
		border: 2px solid black;
		padding: 0.2rem;
	}

	&--mini {
		align-self: center;
		border: 0px;
		border-top: 1px solid #bdbdbd;
		border-left: 1px solid #bdbdbd;
		border-top-left-radius: 5px;
		border-top-right-radius: 0px;
		border-bottom-left-radius: 0px;
		font-size: 0.55rem;
		max-height: 30px;
		max-width: 30px;
		margin: 0px;
	}
}

.badge {
	@extend .cta-transition;

	display: inline-flex;
	align-items: center;
	justify-content: center;
	width: 50px;
	height: 50px;
	line-height: 50px;
	text-align: center;
	color: $white;
	border: 1px solid $secondary;
	background-color: $secondary;
	border-radius: 50%;
	font-size: 1.2rem;
	outline: none;
	cursor: pointer;
	text-decoration: none;

	&--titled {
		width: fit-content;
		height: fit-content;
	}

	&__name {
		display: flex;
		align-items: center;
		text-transform: uppercase;
		margin-left: 1rem;
		font-size: 0.65rem;
		font-weight: 600;
		letter-spacing: 0.5px;
	}

	&.inverted {
		background-color: transparent;
		color: $secondary;
	}

	&.highlighted {
		background-color: transparent;
		border-color: $off-black;
		color: $off-black;

		&:hover {
			color: $off-black;
			background-color: white;
			border-color: $off-black;
		}

		&:active {
			border-color: $off-black;
		}
	}

	&:disabled {
		opacity: 0.4;
	}

	&:not(:disabled) {

		&:active,
		&:focus,
		&:hover {
			@extend .form-element--active;
		}
	}
}

.badge--no-border {
	@extend .badge;

	border: none;

	font-size: 1.5rem;
}

.note_button {
	width: 100%;
	max-width: 300px;
	height: 20px;
	display: flex;
	justify-content: flex-end;
	align-items: center;
	background-color: transparent;
	border: none;
	outline: none;

	span {
		font-family: "Montserrat";
		font-style: normal;
		font-weight: 700;
		font-size: 16px;
		line-height: 20px;
		text-align: center;
		letter-spacing: 0.5px;
		color: #212120;
		margin-left: 20px;
	}

	&:hover,
	&:focus {
		background-color: transparent !important;
	}
}

.remove_list_item {
	width: 40px;
	height: 40px;
	margin-left: 10px;
	background-color: #fff;
	border-radius: 50%;
	border: none;
	outline: none;

	&:hover {
		background-color: $extra-light-gray !important;
	}
}