.custom_icon_with_tooltip {
    display: flex;
    justify-content: center;
    width: fit-content;

    &__icon {
        position: relative;
        width: fit-content;
        height: 20px;
        width: 20px;
        margin-left: 5px;

        .generic_tool_tip {
            display: none;
        }

        &:hover {
            .generic_tool_tip {
                display: block !important;
            }
        }
    }
}