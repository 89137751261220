.global_search_field__container {
    width: 400px;
    height: 36px;
    display: flex;
    position: relative;
    margin-left: auto;
    justify-content: center;
    align-items: center;
    margin-right: 2rem;

    .global_search_field {
        background: white;
        border-radius: 5px;
        width: 350px;

        &--focused {
            border: 2px solid #CCCCCC;
        }

        &__options {
            animation: swing-in-top-fwd .5s cubic-bezier(.175, .885, .32, 1.275) both;
            box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;

            width: 350px;
            position: absolute;
            background: rgb(255, 255, 255);
            top: 40px;
            max-height: 600px;
            overflow-wrap: break-word;
            overflow-y: scroll;
            overflow-x: hidden;
            padding: 0.2rem;
            user-select: none;

            &__section_title {
                cursor: default;
                padding: 0.5rem;
                margin-bottom: 5px;
                color: #B3B3B3;
                font-weight: 600;
                font-size: 13px;
                line-height: 16px;
                letter-spacing: 0.5px;
                text-transform: uppercase;
            }

            &__option {
                display: inline-flex;
                padding: 0.5rem;
                font-size: 16px;
                width: 100%;

                &:hover {
                    transition: 0.2s;
                    background: $extra-light-gray;
                    border-radius: 0.25rem;
                    cursor: pointer;
                }

                &.active {
                    background-color: red;
                }
            }
        }

        label {
            width: 100%;
            display: flex;
            padding: 5px;
        }

        &__input {
            flex: 0.95;
            outline: none;
            border: none;
            padding-left: 0.5rem;
            font-family: $font-family;
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 30px;
            letter-spacing: 0.5px;
        }

        &__icon {
            flex: 0.05;
            display: flex;
            align-items: center;
            cursor: pointer;
        }

        @keyframes swing-in-top-fwd {
            0% {
                transform: rotateX(-100deg);
                transform-origin: top;
                opacity: 0
            }

            100% {
                transform: rotateX(0deg);
                transform-origin: top;
                opacity: 1
            }
        }
    }
}