.container {
  display: flex;
  width: 100%;
  height: fit-content;
  padding: 30px;
  background-color: #F4F3EF;
  border-radius: 10px;
  justify-content: start;

  .dropzone {
    background-color: #F4F3EF;
    border: 3px dashed #CCCCCC;
    border-radius: 10px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    justify-content: center;
    padding: 4% 7%;

    p {
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      text-align: center;
      letter-spacing: 0.5px;
    }

    img {
      margin-bottom: 20px;
    }

  }

  .dropzone_after {
    @extend .dropzone;
    padding: 1% 2%;
    width: 225px;

    p {
      font-size: 14px;
    }
  }

  .images {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
  }

  .image-container {
    position: relative;
    width: 213px;
    height: 280px;
    margin-right: 20px;
    margin-bottom: 70px;
  }

  .image-container img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 5px;
  }

  .mediaAccessLevel {
    margin: 10px;
    margin-top: 30px;
    border: 1px solid lightgray;
    padding: 1px;
    display: flex;
    flex-direction: column;
    color: rgba(0, 0, 0, 0.6);

    select {
      height: 30px;
    }

    :hover {
      color: black;
    }
  }

  .delete-button {
    position: absolute;
    top: 5px;
    right: 5px;
    background-color: #eee;
    border-radius: 50%;
    width: 25px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;

    :hover {
      transition: 0.2s;
      background: rgb(241, 219, 219);
      border-radius: 1rem;
      cursor: pointer;
    }
  }

  .delete-button:hover {
    background-color: #ddd;
  }

  .add-image {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    color: #aaa;
    margin-top: 10px;
  }
}

.container_initial {
  @extend .container;

  justify-content: center;
}

.container_hidden {
  display: none;
}