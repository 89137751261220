.loading_card {
   /*  height: 80vh !important; */
   display: flex;
   overflow: hidden;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   min-height: 85vh;
   animation: 0.2s;
   -webkit-animation: fade-in 1s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
   animation: fade-in 1s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;

   &__text {
      margin-top: 2rem;
      font-size: 20px;
      line-height: 24px;
      color: #555555;
   }

   @-webkit-keyframes fade-in {
      0% {
         opacity: 0
      }

      100% {
         opacity: 1
      }
   }

   @keyframes fade-in {
      0% {
         opacity: 0
      }

      100% {
         opacity: 1
      }
   }
}