.prescription-preview {
    @extend .card;

    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    background-color: #414140;

    &--medium {
        min-height: 600px;
        max-height: 1200px;
    }

    .upload-prescription-message {
        color: $cream-white;
    }

    .upload-prescription-message--d-none {
        display: none;
    }

    label {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        bottom: 20px;
        right: 20px;
        overflow: hidden;
        z-index: 0;
        background: rgba(33, 33, 32, 0.3);

        &.badge {
            border: none;
        }

        svg {
            height: 25px;
            width: 25px;
        }

        input {
            display: none;
        }

        i {
            font-size: 0.8rem;
        }
    }

    &__dropzone {
        background: #555555;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        cursor: pointer;

        &__icon {
            padding: 2rem;
            background: #333333;
            border-radius: 50%;
            transition: all 0.3s ease;

            svg {
                width: 50px;
                height: 50px;
            }

            &:hover {
                background: #666565;
            }
        }

        &__text {
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 20px;
            text-align: center;
            letter-spacing: 0.5px;
            color: #FFFFFF;
            margin-top: 1rem;
        }
    }
}