// Breakpoints
$desktop: 1200px;
$tablet: 992px;
$mobile: 768px;

// Colors
$white: #fff;
$cream-white: #f4f3ef;
$gray: #212120;
$light-gray: #c7c7c7;
$extra-light-gray: #e5e5e5;
$canceled-status-grey: #EFEFEF;
$blue: #51cbce;
$red: #e74c3c;
$green: #16a085;
$yellow: #fa0;
$light-green: #719C80;
$light-green-product-status: #899C71;
$orange: #E77A3C;
$light-orange: #DEA84C;
$extra-light-orange: #FFF5E6;

$off-black: #3b3b3b;
$split-order-text-color: #333333;

$primary: $blue;
$secondary: $gray;
$tertiary: $blue;
$quaternary: $white;
$border-color: #ddd;
$border-color-light: rgba(255, 255, 255, 0.5);

// Typography
$font-family: "Montserrat", sans-serif;
$font-size-mobile: 20px;
$font-size-tablet: 18px;
$font-size-desktop: 16px;

// Spacing
$space-xxs: 5px;
$space-xs: 10px;
$space-s: 20px;
$space-m: 40px;
$space-l: 60px;
$space-xl: 80px;
$space-xxl: 100px;

// Transitions
$form-transition-duration: 0.2s;

// Layout
$header-height: 63px;
$aside-width: 259px;
$aside-width-mobile: 60px;
$column-space: $space-s;

// Border radius
$border-radius-s: 3px;
$border-radius-m: 5px;
$border-radius-l: 10px;

// Table
$table-row-height: 50px;

// Modal
$modal-width: 600px;