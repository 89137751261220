.edit-flag-button {
	position: relative;
	background: transparent;
	outline: none;
	border-radius: 4px;
	display: flex;
	align-items: center;
	cursor: pointer;
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 20px;
	transition: all 0.2s ease;
	letter-spacing: 0.5px;
	flex-wrap: nowrap;
	text-align: center;
	border: none;
	width: 24px;
	height: 30px;

	.edit-flag-button--dropdown {
		display: none;
		outline: none;
		border: none;
		position: absolute;
		top: 100%;
		right: 100%;
		width: 200px;
		box-shadow: 7px 6px 28px 1px rgba(0, 0, 0, 0.26);

		&--expand {
			display: flex;
			flex-direction: column;
			justify-content: center;
			border-radius: 10px;

			>div {
				border-radius: 10px;
				text-align: start;
				padding: 16px 16px;
				background-color: #FFFFFF;

				&:hover {
					transform: translateY(2px);
					transition: 0.2s;
					background-color: #F5F5F5;
				}
			}
		}
	}

	&:hover {
		transition: 0.2s;
		background-color: hsla(0, 0%, 0%, 0.15);
	}
}