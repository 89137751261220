
@import "./_variables";
@import "./components/typography/fonts/fonts";
@import "./utils/reset";
@import "./utils/mixins";
@import "./utils/helpers";
@import "./utils/layout";

@import "./components/algorithm-option";
@import "./components/header";
@import "./components/aside";
@import "./components/scrollbar";
@import "./components/table";
@import "./components/prescription-preview";
@import "./components/modal";
@import "./components/drawer";
@import "./components/drop-zone";
@import "./components/bulk-image-upload-drop-zone";
@import "./components/banner";
@import "./components/table-account-type-icon";
@import "./components/table-rewrite-flag";
@import "./components/custom-icon-with-tooltip";
@import "./components/generic-tooltip";
@import "./components/generic-tag";
@import "./components/loading-card";
@import "./components/customCard";
@import "./components/tableDrawerContainer";
@import "./components/stock-check-table";

// Forms
@import "./components/forms/forms";
@import "./components/forms/button";
@import "./components/forms/bannerButton";
@import "./components/forms/editFlagButton";
@import "./components/forms/checkbox";
@import "./components/forms/squareCheckbox";
@import "./components/forms/checkList";
@import "./components/forms/text-input";
@import "./components/forms/radio-input";
@import "./components/forms/textarea";
@import "./components/forms/dropdown";
@import "./components/forms/toggler";
@import "./components/forms/globalSearchField";
@import "./components/forms/radioSelector";
@import "./components/forms/owerflowSelect";
@import "./components/forms/inputFieldValidationBulletPoints";

// Tooltip contents
@import "./components/contentForToolTips/flaggedPrescriptionTooltipContent";
@import "./components/contentForToolTips/processPrescriptionTooltipContent";
@import "./components/contentForToolTips/productPrescriberNoteTooltipContent";

// Typography
@import "./components/typography/heading";
@import "./components/typography/link";
@import "./components/typography/paragraph";
@import "./components/typography/span";

// Spinner
@import "./components/spinner";
@import "./components/spinnerIcon";

//Separator
@import "./components/separator";

// Global
* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

html {
    font-size: $font-size-mobile;
    font-family: $font-family;
}

body {
    background-color: $cream-white;
    min-width: 1000px;
}

main {
    @extend .p-m;

    margin: $header-height 0 0 $aside-width;
}

@include tablet {
    main {
        margin: $header-height 0 0 $aside-width-mobile;
    }
}

@include mobile {
    main {
        margin: $header-height 0 0 $aside-width-mobile;
    }
}
