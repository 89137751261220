.dropzone {
    display: flex;
    align-items: center;
    justify-content: center;

    &__area {
        width: 512px;
        height: 352px;
        background: #EFEFEF;
        border: 4px dashed #CCCCCC;
        border-radius: 20px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        &--selected {
            border: initial;
        }
    }

    &__text {
        font-style: normal;
        font-weight: bold;
        font-size: 16.4px;
        line-height: 20px;
        text-align: center;
        margin-top: 1rem;
    }

    &__select_file {
        font-style: normal;
        font-weight: bold;
        font-size: 16.4px;
        line-height: 20px;
        text-align: center;
        color: blue;
        cursor: pointer;
    }
}