aside {
	position: fixed;
	left: 0;
	bottom: 0;
	top: 0;
	width: $aside-width;
	background: $gray;
	z-index: 11;

	.logo-container {
		@extend .m-t-xs;
		@extend .m-h-xs;

		height: $header-height - $space-xs;
		border-bottom: 1px solid $border-color-light;
		user-select: none;

		img {
			width: 65%;
			margin-top: 10px;
			margin-left: 30px;
		}
	}

	.primary-navigation {
		@extend .m-t-s;

		height: calc(100vh - #{$header-height + $space-xs + $space-s});
		overflow-y: auto;
		overflow-x: hidden;
		direction: rtl;

		* {
			direction: ltr;
		}

		ul {
			list-style-type: none;
			user-select: none;

			li {
				a {
					@extend .p-h-s;

					position: relative;
					display: block;
					width: 100%;
					color: darken($white, 30%);
					height: 55px;
					line-height: 60px;
					text-transform: uppercase;
					text-decoration: none;

					svg {
						height: 100%;
					}

					&:hover,
					&:focus {
						color: $white;
						border-right: 4px solid darken($white, 30%);

						& svg {
							fill: #ffffff;

							& g {
								fill: #ffffff;
							}
						}

						transition: 0.2s;
					}

					&.active {
						color: $white;
						border-right: 4px solid $white;
						background-color: transparentize($white, 0.95);

						& svg {
							fill: #ffffff;
						}
					}

					i {
						@extend .m-r-s;

						font-size: 0.9rem;
					}

					span {
						font-size: 0.6rem;
						vertical-align: top;
					}

					&:focus~.secondary-navigation,
					&:active~.secondary-navigation {
						display: block;
					}
				}

				.secondary-navigation {
					@extend .p-b-s;

					position: fixed;
					display: none;
					padding-top: 83px;
					left: $aside-width;
					width: $aside-width;
					background: transparentize($gray, 0.02);
					height: 100vh;
					top: 0;
					animation: swing-in-left-fwd 0.3s cubic-bezier(0.075, 0.820, 0.165, 1.000) both;
				}

				&:hover {
					.secondary-navigation {
						display: block;
					}
				}
			}
		}
	}

	@-webkit-keyframes swing-in-left-fwd {
		0% {
			-webkit-transform: rotateY(100deg);
			transform: rotateY(100deg);
			-webkit-transform-origin: left;
			transform-origin: left;
			opacity: 0
		}

		100% {
			-webkit-transform: rotateY(0);
			transform: rotateY(0);
			-webkit-transform-origin: left;
			transform-origin: left;
			opacity: 1
		}
	}

	@keyframes swing-in-left-fwd {
		0% {
			-webkit-transform: rotateY(100deg);
			transform: rotateY(100deg);
			-webkit-transform-origin: left;
			transform-origin: left;
			opacity: 0
		}

		100% {
			-webkit-transform: rotateY(0);
			transform: rotateY(0);
			-webkit-transform-origin: left;
			transform-origin: left;
			opacity: 1
		}
	}
}

@include tablet {
	aside {
		width: $aside-width-mobile;

		img {
			display: none;
		}

		.primary-navigation {
			&>ul {
				&>li {
					&>a {
						&.active::after {
							right: -9px;
						}

						span {
							display: none;
						}
					}

					.secondary-navigation {
						left: $aside-width-mobile;
					}
				}
			}
		}
	}
}

@include mobile {
	aside {
		width: $aside-width-mobile;

		img {
			display: none;
		}

		.primary-navigation {
			&>ul {
				&>li {
					&>a {
						&.active::after {
							right: -9px;
						}

						span {
							display: none;
						}
					}

					.secondary-navigation {
						left: $aside-width-mobile;
					}
				}
			}
		}
	}
}