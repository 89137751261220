:disabled,
:disabled~label {
	opacity: 0.4;
}

.component-label {
	@extend .cta-transition;

	position: absolute;
	display: flex;
	flex-flow: wrap-reverse;
	top: -20px;
	left: 0;
	height: 16px;
	text-transform: uppercase;
	font-size: 0.65rem;
	font-weight: 600;
	letter-spacing: 0.5px;

	&.centered {
		font-size: 0.55rem;
		font-weight: 600;
		width: inherit;
		justify-content: center;
	}
}

.centered-form {
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translateX(-50%) translateY(-50%);
	width: 500px;

	.form-row {
		display: flex;
		justify-content: center;
	}
}

.disclaimer {
	@extend .m-t-s;

	display: block;
	font-size: 0.65rem;
	width: 100%;
	text-align: center;

	&.error {
		color: $red;
	}
}

.credential {
	display: block;

	font-size: 14px;
	letter-spacing: 0.5px;
	cursor: pointer;
	text-decoration: underline;
	color: #2F3EFB;

	&.reset {
		text-align: right;
	}

	&.login {
		text-align: center;
	}
}

.table-row {
	justify-content: end !important;
	padding-top: 0px;
}

.organisation_details {
	flex-direction: column;
	align-items: flex-start;
	justify-content: center;
	gap: 10px;

	.text_input-label {
		font-weight: 600;
		font-size: 13px;
		line-height: 16px;
		letter-spacing: 0.5px;
		text-transform: uppercase;
	}

	p {
		font-weight: 400;
		font-size: 14px;
		line-height: 17px;
		letter-spacing: 0.5px;
		color: #212120;
	}
}


.organisations_select-dropdown {
	.react-dropdown-select-dropdown {
		overflow-x: hidden;
		height: auto;
	}

	.remove_linked_organisation {
		width: 35px;
		height: 35px;
		margin-left: 10px;
		background-color: #fff;
		border: none;

		&:hover {
			background-color: #fff;
			transition: 0.2s;
		}
	}
}

.add_list_element_button {
	display: flex;
	justify-content: flex-start;
	width: 100%;
	max-width: 300px;
	align-items: center;
	cursor: pointer;
	border-radius: 15px;
	height: 40px;
	padding: 5px;

	.m-l-8 {
		margin-left: 8px;
		font-size: 14px;
	}

	&:hover {
		background: linear-gradient(90deg, $extra-light-gray, $white);
	}
}

.ordered_products_element {
	padding: 5px;
}

// Focus fields
input:focus~.animated-border,
select:focus~.animated-border,
textarea:focus~.animated-border {
	width: 100%;
	left: 0;
}

input:focus~label,
select:focus~label,
textarea:focus~label {
	color: $tertiary;
}

// Error fields
input.error~.animated-border,
select.error~.animated-border,
textarea.error~.animated-border {
	background: linear-gradient(to right, transparent, $red, $red, $red, $red, $red, $red, $red, $red, $red, $red, $red, $red, transparent);
}

input.error~label,
select.error~label,
textarea.error~label {
	color: $red;
}

.j-c-s {
	justify-content: flex-start;
	gap: 15px;
}

.j-c-sb {
	display: flex;
	justify-content: space-between;
}