.order-selected-checkbox {
  margin: 0;
}

.stock-table-order-container {
  padding: 12px;
  background-color: #F7F7F7;
  width: 100%;

  .square-checkbox-container {
    margin-left: 20px;
  }
}